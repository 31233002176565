/**=====================
  38. Landing CSS Start
==========================**/

@media(max-width:1880px){
  // landign home
  .landing-page {
    .landing-home {
      margin-bottom: 175px;
      .animat-block{
        li{
          &:first-child{
            bottom: -16%;
            img{
              height: 460px;
            }
          }
          &:nth-child(2){       
            bottom: 18%;
            left: 22%;       
            img{
              height: 160px;
            }    
          }
          &:nth-child(3){     
            bottom: -22%;
            right: 17%;      
            img{
              height: 190px;
            }
          }
        }
      }
      .landing-home-contain{
        padding-top:45px;
      }
    }
  }
}
@media (max-width:1660px) {
  .landing-page{
    .landing-home{
      margin-bottom: 160px;
      .animat-block{
        li{
          &:first-child{
            bottom: -12%;
            left: 25%;
            img{
              height: 440px;
            }
          }
          &:nth-child(2){       
            bottom: 21%;
            left: 20%;       
            img{
              height: 140px;
            }    
          }
          &:nth-child(3){     
            bottom: -17%;
            right: 19%;
            img{
              height: 160px;
            }
          }
        }
      }
      .landing-home-contain{
        p{
          width: 60%;
          margin-bottom: 22px;
        }
      }
    }
  }
}

@media (max-width:1469px) {
  .landing-page{
    .landing-home{
      margin-bottom: 145px;
      .landing-home-contain{
        height: calc(100vh - 120px);
        padding-top: 40px;
      }
      .animat-block{
        li{
          &:first-child{
            bottom: -16%;
            left: 23%;
          }
          &:nth-child(2){
            bottom: 20%;     
            left: 17%;
          }
          &:nth-child(3){     
            bottom: -21%;
            right: 17%;
          }
          &:nth-child(8){
            top: 28%;
            right: 16%;
          }
        }
      }
    }
  }
}
@media(max-width: 1365px) {
  .landing-page{
    .landing-home{
      margin-bottom: 135px;
      .animat-block{
        li{
          &:first-child{
            bottom: -13%;
            left: 22%;
            img{
              height: 400px;
            }
          }
          &:nth-child(2){       
            bottom: 20%;
            left: 16%;
            img{
              height: 120px;
            }    
          }
          &:nth-child(3){     
            bottom: -18%;
            right: 18%;
            img{
              height: 140px;
            }
          }
        }
      }
      .landing-home-contain{
        h2{
          font-size: 46px;
        }
      }
    }
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .landing-page {
    // Demo section
    .demo-section {
      .demo-box {
        padding: 15px;
        .demo-title {
          padding-top: 10px;
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width:1199px) {
  .landing-page{
    .landing-home{
      margin-bottom: 90px;
      .animat-block{
        li{
          &:first-child{
            bottom: -8%;
            left: 21%;
            img{
              height: 360px;
            }
          }
          &:nth-child(2){       
            bottom: 24%;
            img{
              height: 100px;
            }    
          }
          &:nth-child(3){     
            bottom: -11%;
            right: 20%;
            img{
              height: 120px;
            }
          }
          &:nth-child(6){
            top:15%;
          }
        }
      }
      .landing-home-contain{
        height: calc(100vh - 165px);
        .landing-icon{
          li{
            img{
              height: 28px;
            }
          }
        }
        h2{
          font-size: 34px;
        }
        p{
          width: 60%;
          margin-bottom: 22px;
        }
      }
    }
  }
  .landing-page {
    .section-py-space {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .section-pt-space {
      padding-top: 60px;
    }

    .section-pb-space {
      padding-bottom: 60px;
    }

    .title {
      h1 {
        font-size: 28px;
      }
    }
    // header //
    .landing-header {
      ul.landing-menu {
        li.nav-item {
          a.nav-link {
            padding: 10px 10px;
          }
        }
      }
      .buy-block {
        .btn-landing {
          padding: 10px 20px;
        }
      }
    }
    // landing home 
    .landing-home {
      .landing-home-contain {
        h2 {
          font-size: 26px;
        }
      }
    }
    // counter-sec
    .counter-sec {
      .counter-box {
        padding: 20px 20px;
        .count-number {
          width: 60px;
          height: 60px;
          h3 {
            font-size: 22px;
          }
        }
      }
    }
    // demo section 
    .demo-section {
      .demo-box {
        padding: 20px;
        .img-wrraper {
          ul.demo-link {
            li {
              a {
                display: block;
                svg {
                  display: block;
                  margin-right: auto;
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
    // framework //
    .framework {
      .nav {
        margin-bottom: 30px;
      }
      ul.framworks-list {
        li {
          width: 150px;
          height: 150px;
          img {
            height: 40px;
          }
        }
      }
    }
  }
}
@media (max-width: 1140px) {
  .landing-page{
    .landing-home{
      .animat-block{
        li{
          &:first-child{
            bottom: -6%;
            left: 21%;
            img{
              height: 360px;
            }
          }
          &:nth-child(2){       
            bottom: 27%;
            img{
              height: 100px;
            }    
          }
          &:nth-child(3){     
            bottom: -9%;
            right: 12%;
            img{
              height: 120px;
            }
          }
        }
      }
    }
  }
}
@media(max-width:991px){    
  .landing-page {
    .demo-section{
      .demo-block{
        padding: 0 18px;
      }
    }
    .landing-home{
      .animat-block{
        li{
          &:first-child{
            left: 16%;
            bottom: -11%;
            img{
              height: 300px;
            }
          }
          &:nth-child(2){
            bottom: 17%;
            left: 8%;
          }
          &:nth-child(3){
            bottom: -17%;
            right: 7%;
          }
          &:nth-child(7){
            bottom: 8%;
            left: 8%;
          }
        }
      }
    }
    .section-py-space {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .section-pt-space {
      padding-top: 50px;
    }

    .section-pb-space {
      padding-bottom: 50px;
    }

    //landing header//
    .landing-header {
      padding-top: 15px;
      padding-bottom: 15px;
      ul.landing-menu {
        position: fixed;
        top: 0;
        right: -320px;
        width: 300px;
        height: 100vh;
        background-color: $white;
        display: block;
        transition: all 0.5s ease;
        &.open {
          right: 0;
        }
        li.nav-item {
          display: block;
          background-color: $white;
          text-align: left;
          &.menu-back {
            display: block;
          }
          a.nav-link{
            color: $theme-body-font-color;
            padding: 10px 20px;
          }
          &:hover{
            a.nav-link{
              color: $primary-color;
            }
          }
        }
      }
       
      .buy-block {
        .toggle-menu {
          display: block;
        }
      }
    }
    .btn-landing {
      padding: 10px 25px;
    }
    // landing-home
    .landing-home {
      position: relative;
      z-index: 1;
      .landing-home-contain {
        text-align: center;
        height: 640px;
        width: 80%;
        margin: auto;
        padding-top: 0;
        padding-bottom: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
          width:90%;
        }
        .btn-landing {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    // framework
    .framework {
      .nav {
        .nav-link {
          img {
            height: 35px;
            width: auto;
          }
        }
        .nav-item {
          &:nth-child(n+2) {
            margin-left: 10px;
          }
        }
      }
    }
    // counter-sec
    .counter-sec {
      .counter-block {
        margin-bottom: -30px;
        >div {
          margin-bottom: 30px;
        }
      }
      .counter-box {
        padding: 35px 25px;
      }
    }
    // footer
    .landing-footer {
      .footer-contain {
        img {
          height: 100px;
        }
        .star-rate {
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media(max-width:767px) {
  .landing-page {
    .title {
      h1 {
        font-size: 24px;
      }
    }
    .section-py-space {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .section-pt-space {
      padding-top: 40px;
    }
    .section-pb-space {
      padding-bottom: 40px;
    }
    // landing home 
    .landing-home {
      margin-bottom: 0;
      .landing-home-contain {
        height: 520px;
        padding-top: 60px;
        padding-bottom: 0;
        h6 {
          margin-bottom: 10px;
        }       
        p {
          width: auto;
          margin-bottom: 25px;
        }
      }
      .animat-block {
        li{         
          z-index: -1;
          &:first-child,&:nth-child(2),&:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6),&:nth-child(7),&:nth-child(8){
            display: none;
          }
        }
      }     
    }
    //demo section
    .demo-section {
      .demo-block {
        margin-bottom: -20px;

        >div {
          margin-bottom: 20px;
        }
      }
      .demo-box {
        width: fit-content;
        margin: auto;

        .img-wrraper {
          ul.demo-link {
            li {
              &:nth-child(n+2) {
                margin-left: 5px;
              }

              a {
                font-size: 10px;
              }
            }
          }
        }
        .demo-title {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
    // counter sec
    .counter-sec {
      .counter-box {
        .count-number {
          width: 50px;
          height: 50px;
        }

        .count-detail {
          h4 {
            font-size: 20px;
          }
        }
      }
    }
    // core feature
    .core-feature {
      .feature-box {
        .icon-wrraper {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
        }

        h4 {
          font-size: 20px;
        }
      }
    }
    // framework
    .framework {
      .nav {

        .nav-link {
          img {
            height: 35px;
          }

          h5 {
            font-size: 16px;
          }

          &.active {
            &:before {
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 15px solid rgba($primary-color, 0.8);
            }
          }
        }
      }
      ul.framworks-list {
        li {
          width: 145px;
          height: 145px;

          img {
            height: 40px;
          }
        }
      }
    }
    // footer 
    .landing-footer {
      .footer-contain {
        img {
          height: 80px;
          margin-bottom: 10px;
        }
        h2 {
          margin-bottom: 5px;
        }
        p {
          margin-bottom: 10px;
        }
        .star-rate {
          margin-bottom: 25px;
        }
        .btn-footer {
          .btn {
            padding: 10px 25px;
            font-size: 14px;
            line-height: 1;
          }
        }
      }
    }
  }
}
@media(max-width:575px) {
  .landing-page {
    .custom-container {
      padding-left: 15px;
      padding-right: 15px;
    }
    .theme-color{
      li>div{
        height: 30px;
        width: 30px;
        margin: 5px;
        a{
          img{
            max-width: 130px;
          }
        }
      }
    }
    // landing home
    .landing-home {
      .landing-home-contain{
        width: auto;       
      }
    }
    // demo-section
    .demo-section {
      .demo-box {
        padding: 15px;

        .demo-title {
          h3 {
            font-size: 16px;
          }
        }
      }
    }
    // framework
    .framework {
      .nav {
        display: flex;
      }
    }
    // core feature
    .core-feature {
      .feature-box {
        padding: 30px 20px;
      }
    }
    // counter sec feature //  
    .counter-sec {
      .counter-box {
        height: auto;
        padding: 30px 20px;
        .count-number {
          width: 45px;
          height: 45px;
          margin-bottom: 10px;
          h3 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media(max-width:480px) {
  .landing-page {
    .demo-section{
      .demo-block{
        padding: 0 12px;
      }
    }
    .title {
      h1 {
        font-size: 22px;
      }
    }
    // landing page
    .landing-home {
      .landing-home-contain {
        h2{
          font-size:22px;
        }
      }
    }
    // header 
    .landing-header {
      .buy-block {
        .toggle-menu {
          margin-left: 10px;
        }
      }
    }
    // framework  
    .framework {
      .nav {
        .nav-item {
          width: auto;
        }
        .nav-link {
          display: block !important;
          padding: 10px 15px;

          &.active {
            &:before {
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 5px solid rgba($primary-color, 0.8);
            }
          }
          img {
            height: 20px;
            margin-right: unset;
          }
          .text-start {
            display: none;
          }
        }
      }
      ul.framworks-list {
        li {
          width: 110px;
          height: 110px;
          padding: 25px 0;
          img {
            height: 30px;
          }
        }
      }
    }
    // footer 
    .landing-footer {
      .footer-contain {
        .btn-footer {
          a:nth-child(n+2) {
            margin-left: 5px;
          }

          .btn {
            padding: 10px 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 420px) {
  .landing-page{
    .landing-home{
      .landing-home-contain{
        .landing-icon{
          li{
            img{
              height: 24px;
            }
            a{
              font-size: 16px;
            }
          }
        }
      }
    }
    .theme-color{
      li>div{
        a{
          img{
            left: -50px;
          }
        }
      }
    }
  }
}
@media(max-width:360px) {
  .landing-page {
    .theme-color{
      li{
        &:first-child{
          a{
            img{
              left: -30px;
            }
          }
        }
      }
      li>div{
        a{
          img{
            left: -70px;
          }
        }
      }
    }
    .landing-footer {
      .footer-contain {
        .btn-footer {
          .btn {
            &:last-child {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
/**=====================
     38. Landing CSS Ends
==========================**/